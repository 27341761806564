@import "~@/styles/variables/variables.scss";


































































































































































































































::v-deep {
	.pagination-box {
		position: absolute;
		bottom: 0;
	}
	.filter {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 0 0 24px;
		flex-wrap: wrap;
		.search {
			margin-right: 20px;
			margin-top: 20px;
		}
		.btn-ops {
			margin-top: 20px;
			margin-left: auto;
			.el-button {
				border-radius: 8px;
			}
		}
	}
}

@import "~@/styles/variables/variables.scss";


















































































































































































.el-button {
	width: 100px;
	height: 36px;
}
.gc-detail-tab {
	flex: 1;
	overflow: hidden;
}

.page-main {
	&-left {
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 25px 12px 15px 20px;
		.box-select {
			margin-top: 12px;
		}
		.items {
			margin-top: 10px;
			overflow-y: scroll;
			height: 0;
			flex: 1;
			.item {
				cursor: pointer;
				height: 40px;
				line-height: 40px;
				padding: 0 13px;
				background: #f0f0f0;
				font-weight: 400;
				color: #666666;
				border-radius: 8px;
				&.active {
					background: #ebedfa;
					color: #4d6bff;
				}
				+ .item {
					margin-top: 10px;
				}
			}
		}
	}
	&-right {
		padding: 12px 24px;
		::v-deep .el-tabs__nav-scroll {
			.el-tabs__item {
				&.is-top:last-child {
					padding-right: 0;
				}
				&.is-top:nth-child(2) {
					padding-left: 0;
				}
			}
		}
	}
}

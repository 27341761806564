@import "~@/styles/variables/variables.scss";
































































































































































.tab-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	.title {
		font-size: 15px;
		color: #000;
		line-height: 40px;
		margin-top: 32px;
		em {
			color: #999;
		}
	}
	::v-deep .btn-staff {
		min-width: 80px;
		height: 30px;
		padding: 0 16px;
		border-radius: 16px;
		span {
			font-size: 12px;
		}
	}
	.table {
		margin-top: 24px;
		flex: 1;
	}
	::v-deep .ops-btn {
		cursor: pointer;
		color: $base-color-theme;
	}
}
.el-row {
	& + .el-row {
		margin-top: 26px;
	}
	.el-col {
		display: flex;
		align-items: center;
		.label {
			color: #999;
			margin-right: 4px;
		}
		.el-form-item {
			max-width: 260px;
			margin-bottom: 0;
		}
	}
}
.mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	@include flex-center;
	.content {
		width: 400px;
		height: 400px;
		background: #fff;
		border-radius: 4px;
		padding: 20px;
		position: relative;
		.icon {
			font-size: 18px;
			position: absolute;
			right: 0;
			top: -28px;
			cursor: pointer;
		}
		.qrcode {
			width: 100%;
			height: 100%;
		}
	}
}
